import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Tabbar, TabbarItem } from 'vant'; 
import { Cell, CellGroup } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Divider } from 'vant';
import { Step, Steps } from 'vant';
import { Uploader } from 'vant';
import { Image as VanImage } from 'vant';
import { Tag } from 'vant';
import { Calendar } from 'vant';
// import { Icon } from 'vant';
import { Popup } from 'vant';
import { Cascader } from 'vant';
import { Area } from 'vant';
import { Badge } from 'vant';
import { Tab, Tabs } from 'vant';
import { Search } from 'vant';
import { Rate } from 'vant';
import { Icon } from 'vant';
import { Empty } from 'vant';
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Badge);
Vue.use(Area);
Vue.use(Cascader);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Calendar);
Vue.use(Tag);
Vue.use(VanImage);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Uploader);
Vue.use(Rate);
Vue.use(Icon);
Vue.use(Empty);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
